<template>
  <div
    v-if="dots > 0"
    class="text-[8px] uppercase font-medium tracking-md"
  >
    <span v-if="title">{{ title }}</span>
    <div
      class="mt-2 whitespace-nowrap"
    >
      <div
        v-for="n in maxDots"
        :key="n"
        class="rounded-full mr-2 border-grey400 border inline-block bg-white"
        :class="[
          `h-${size} w-${size}`,
          {
            '!bg-black': n <= dots,
          }
        ]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title?: string,
  dots: number,
  size?: number,
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  size: 8,
  dots: 0,
});

const maxDots = computed<number>(()=> {
  return props.dots > 4 ? 6 : 4;
});

</script>

<style scoped lang="postcss">
</style>
